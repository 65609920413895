export function routes($stateProvider) {
  $stateProvider
    .state('app.ev', {
      url: '/ev',
      abstract: true,
      template: '<ui-view></ui-view>'
    }).state('app.ev.list', {
      url: '?{page:int}&{term:string}&{order:string}&{sort:string}&{tag:string}',
      template: require('./list/view.html'),
      controller: 'EvChargerListController',
      controllerAs: 'vm',
      role: ['smartmeterEdit']
    }).state('app.ev.details', {
      url: '/{id}',
      template: require('./details/view.html'),
      controller: 'EvChargerDetailsController',
      controllerAs: 'vm',
      role: ['smartmeterEdit']
    }).state('app.ev.new', {
      url: '/add',
      template: require('./details/view.html'),
      controller: 'EvChargerDetailsController',
      controllerAs: 'vm',
      role: ['smartmeterEdit']
    });
}

routes.$inject = ["$stateProvider"];
