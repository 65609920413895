export default class DeviceSercice {
  constructor(Device, $q, $http, Measure) {
    this.Device = Device;
    this.$q = $q;
    this.$http = $http;
    this.Measure = Measure;
  }

  list = (filter) => {
    let defer = this.$q.defer();

    filter = filter || {};
    filter.where = filter.where || {};

    this.Device.count({
      where: filter.where,
    })
      .$promise.then((c) => {
        this.Device.find(filter)
          .$promise.then((r) => {
            defer.resolve({
              total: c.count,
              data: r,
            });
          })
          .catch((e) => {
            defer.reject(e);
          });
      })
      .catch((e) => {
        defer.reject(e);
      });
    return defer.promise;
  };

  get = id => {
    return this.Device.findById({ id: id }).$promise;
  }

  save = (data) => {
    let defer = this.$q.defer();
    data.lastModified = new Date();
    this.Device.upsert(data)
      .$promise.then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  getPhoto = (file) => {
    let defer = this.$q.defer();
    this.$http.get('api/assets/containers/devices/files/' + file + '/download', { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  }

  units = () => {
    let defer = this.$q.defer();
    this.Measure.find()
      .$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };
}

DeviceSercice.$inject = ['Device', '$q', '$http', 'Measure'];
