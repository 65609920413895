import { before, max } from "lodash";

const moment = require("moment-timezone");
export default class DashboardEnergyController {
  constructor(DashboardEnergy, $interval, $scope, $state, AlarmService, $http, AuthenticationService) {
    this.AuthenticationService = AuthenticationService;
    this.DashboardEnergy = DashboardEnergy;
    this.Alarm = AlarmService;
    this.$interval = $interval;
    this.$scope = $scope;
    this.$state = $state;
    this.$http = $http;

    this.user = AuthenticationService.getUser();

    $scope.$on('$inlineHelpUpdate', () => {
      this.user = AuthenticationService.getUser();
    });

    // Aggregate values
    this.aggregatesLoaded = false;
    this.totalProduction = 0;
    this.totalGrid = 0;
    this.totalReturn = 0;
    this.totalProductionLiquid = 0;
    this.costProduction = 0;
    this.costGrid = 0;
    this.costReturn = 0;
    this.costProductionLiquid = 0;

    this.selfConsumedSolarEnergyRatio = 0;
    this.selfSufficiencyRatio = 0;

    this.day = moment.utc($state.params.date);

    this.getData();

    $scope.$on("$destroy", () => {
      if (this.interval) $interval.cancel(this.interval);
      this.clearPotencyTimers();
    });
  }

  isToday = () => moment.utc().isSame(this.day, 'day');

  moveTimeline = inc => {
    this.day = moment.utc(this.day).add(inc, 'day');
    this.getLast24hrs();
  }

  setDayLabel = () => {
    if (this.isToday()) {
      return "Hoje";
    } else {
      return moment.utc(this.day).format("DD/MM/YYYY")
    }
  }

  clearPotencyTimers = () => {
    if (this.potencyInterval) this.$interval.cancel(this.potencyInterval);
    this.potencyInterval = undefined;
  };

  setPotencyTimers = () => {
    this.clearPotencyTimers();
    if (!this.$scope.$$destroyed) {
      this.potencyInterval = this.$interval(() => {
        this.updatePotencyData();
      }, 0.5 * 1000 * 60);
    }
  };

  setFractionFor = chart => chart.value > 10 ? 0 : 3;

  updatePotencyData = () => {
    if (this.consumptionGauge) this.consumptionGauge.value = 0; // Only if potencies exist
    this.potencyCharts.forEach(p => {
      this.DashboardEnergy.getOpenEnergyData(p.feedId).then(response => {
        p.value = Number(response.p) / 1000; // W to kW
        p.time = moment();
        let o = angular.copy(p.value);
        if (p.value < 0) { // If negative, absolute it and change label for 'Devolvido'
          p.value = -p.value;
          p.label = 'Devolvido';
        } else { // Just update the label
          p.label = '';
        }
        if (p.name.includes("Excedente")) {
          o *= -1;
        }
        this.consumptionGauge.value += o;
      }).catch(err => {
        this.valor = null;
      });
    });
    this.setPotencyTimers();
  };

  createPotencyChart = (r) => {
    this.potencyCharts = [];
    r.forEach(p => {
      let t = 0;
      if (p.potency) {
        // Check for grid
        if (p.potency.grid) {
          this.potencyCharts.push({
            //name: p.name,
            //type: 'Rede',
            name: 'Rede',
            id: 0,
            label: '',
            time: moment(),
            feedId: p.potency.grid.feedId,
            capacity: p.potency.grid.capacity / 1000, // W to kW
            color: '#AA3033',
            value: 0
          });
          t += p.potency.grid.capacity / 1000;
        }

        // Check for production
        if (p.potency.production) {
          this.potencyCharts.push({
            //name: p.name,
            //type: 'Produção',
            name: 'Produção Bruta',
            label: '',
            time: moment(),
            id: 2,
            feedId: p.potency.production.feedId,
            capacity: p.potency.production.capacity / 1000, // W to kW
            color: '#FDA50F',
            value: 0
          });
          t += p.potency.production.capacity / 1000;
        }
        // Check for Excedente
        if (p.potency.return) {
          this.potencyCharts.push({
            //name: p.name,
            //type: 'Excedente',
            name: 'Excedente',
            label: '',
            time: moment(),
            id: 3,
            feedId: p.potency.return.feedId,
            capacity: p.potency.return.capacity / 1000, // W to kW
            color: '#9986C1',
            value: 0
          });
        }
        // Cria "consumo total"
        this.consumptionGauge = {
          //name: p.name,
          //type: 'Consumo',
          name: 'Total',
          label: '',
          capacity: t,
          time: moment(),
          color: '#01419a',
          value: 0
        }
      }
    });
    this.updatePotencyData();

    // this.setPotencyTimers(); // Trigger the updates
  };

  getLast24hrs = () => {
    this.last24hrsLoaded = false;
    this.aggregatesLoaded = false;
    this.DashboardEnergy.last24hrs(this.day).then((r) => {
      // this.createLast24HrsChart(r);
      this.createDayChart(r);
      this.last24hrsLoaded = true;
      this.aggregatesLoaded = true;
    });
  }

  getData = () => {
    this.potencyLoaded = false;
    this.DashboardEnergy.registeredPotency().then((r) => {
      this.createPotencyChart(r);
      this.potencyLoaded = true;
    });

    this.last24hrsLoaded = false;
    this.aggregatesLoaded = false;
    this.DashboardEnergy.last24hrs(this.day).then((r) => {
      // this.createLast24HrsChart(r);
      this.createDayChart(r);
      this.last24hrsLoaded = true;
      this.aggregatesLoaded = true;
    });

    // UNCOMMENT FOR 2 WEEK CHART
    // this.consumptionLoaded = false;
    // // Energy Consumption - using measureId
    // this.DashboardEnergy.dashboardConsumption('60acdc1c0b903917588ec458', false, true).then((r) => {
    //   this.createConsumptionChart(r);
    //   this.consumptionLoaded = true;
    // });

    // this.financeLoaded = false;
    // this.DashboardEnergy.dashboardFinance().then((r) => {
    //   this.createFinanceChart(r);
    //   this.financeLoaded = true;
    // });

    this.consumptionMonthLoaded = false;
    this.DashboardEnergy.dashboardConsumption('60acdc1c0b903917588ec458', true, true).then((r) => {
      this.createConsumptionMonthChart(r);
      this.consumptionMonthLoaded = true;
    });

    this.financeMonthLoaded = false;
    this.DashboardEnergy.dashboardFinance(true).then((r) => {
      this.createFinanceMonthChart(r);
      this.financeMonthLoaded = true;
    });

    this.consumptionYearLoaded = false;
    this.DashboardEnergy.dashboardConsumptionYear('60acdc1c0b903917588ec458', true).then((r) => {
      this.createConsumptionYearChart(r);
      this.generateTable(r);
      this.consumptionYearLoaded = true;
    });
  };

  generateTable = data => {
    this.table = [];
    this.tableLoaded = false;
    data.forEach(r => {
      let brute = r.production + (-r.return);
      let unused = r.production + r.return;
      this.table.push({
        date: r.date,
        consumptionKwh: r.consumption,
        productionKwh: r.production,// + (-r.return), // Brute value, so we need the real return value
        consumptionCost: r.consumptionCost || 0,
        //productionCost: (r.productionCost + r.returnCost) || 0,
        productionCost: (r.productionCost) || 0,
        selfConsumedSolarEnergyRatio: (100 - ( r.production / (r.production + (-r.return)) * 100)) || 0, // Não utilizado
        selfSufficiencyRatio: ((r.production / (r.production + r.consumption)) * 100 ) || 0// autosuficiencia
      });
    });
    this.tableLoaded = true;
  }

  createConsumptionChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[], [], [], []];
    //let series = ["Total", "Solar", "Rede", "Excedente Solar"];
    let series = ["Solar consumido", "Rede", "Excedente Solar"];
    //let colors = ["#0277BD", "#FDA50F", "#AA3033", "#512DA8"];
    let colors = ["#FDA50F", "#AA3033", "#512DA8"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push((row.consumption + row.production).round(3));
      data[1].push(row.production);
      data[2].push(row.consumption);
      data[3].push(row.return);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
        // Definir cor das linhas
        pointHoverBorderColor: colors[i],
        pointHoverBackgroundColor: colors[i],
        //type: i == 0 ? "line" : "bar", // Different for line charts (series 0)
        //fill: i == 0 ? false : true // Different for line charts (series 0)
      });
    });

    this.consumption = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time", // add this!
              parser: 'D/MM HH:mm',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                }
              },
            }
          ],

          yAxes: [
            {
              stacked: true,
              id: "kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createAvgData = (color, config, callback, ...values) => {
    const longestArrayLength = Math.max(...values.map(arr => arr.length));
    const summedValues = Array.from({ length: longestArrayLength }, (_, i) =>
        callback(values.map((v) => v[i] || 0))
    );
    const zeroIndex = summedValues.findIndex((currentValue, index, array) => currentValue === 0 && array[index + 1] !== 0); //index of the first 0 followed by != 0
    const totalElements = summedValues.length - (zeroIndex + 1);
    const accumulatedValue = summedValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const avg = (accumulatedValue / totalElements).toFixed(3);
    const avg_values = Array(summedValues.length).fill(avg);
    const [r, g, b] = color;
    const line = {
      data: avg_values,
      type: 'line',
      backgroundColor: `rgba(${r}, ${g}, ${b}, 0)`,
      pointRadius: 0,
      pointHoverRadius: 0,
      borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
      pointHoverBorderColor: `rgba(${r}, ${g}, ${b}, 1)`,
      pointHoverBackgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)`,
      hidden: true,
      borderDash: [6,6],
    };
    return { data: avg_values, dataset: Object.assign(line, config) };
  }

  createConsumptionMonthChart = (origin) => {
    let labels = [];
    let datasets = [];
    //let data = [[], [], [], []];
    let data = [[], [], []];
    //let series = ["Total", "Solar", "Rede", "Excedente Solar"];
    //let colors = ["#0277BD", "#FDA50F", "#AA3033", "#512DA8"];
    let series = ["Solar consumido", "Rede", "Excedente Solar"];
    let colors = ["#FDA50F", "#AA3033", "#512DA8"];

    origin.forEach((row) => {
      labels.push(row.date);
      //data[0].push((row.consumption + row.production).round(3));
      //data[1].push(row.production);
      //data[2].push(row.consumption);
      //data[3].push(row.return);
      data[0].push(row.production);
      data[1].push(row.consumption);
      data[2].push(row.return);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
        // Definir cor das linhas
        pointHoverBorderColor: colors[i],
        pointHoverBackgroundColor: colors[i],
        //type: i == 0 ? "line" : "bar", // Different for line charts (series 0)
        //fill: i == 0 ? false : true // Different for line charts (series 0)
      });
    });

    const {data: consAvgData, dataset: consAvgDataset} = this.createAvgData([212, 107, 33], {
      label: 'Média Consumo'
    }, (vals) => (vals[0] + vals[1]), data[0], data[1]);

    this.consumptionMonth = {
      labels: labels,
      data: [consAvgData, ...data],
      datasets: [consAvgDataset, ...datasets],
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              stacked: true,
              id: "kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionYearChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[], [], []];
    let series = ["Solar consumido", "Rede", "Excedente Solar"];
    let colors = ["#FDA50F", "#AA3033", "#512DA8"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.production);
      data[1].push(row.consumption);
      data[2].push(row.return);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    const {data: consAvgData, dataset: consAvgDataset} = this.createAvgData([212, 107, 33], {
      label: 'Média Consumo'
    }, (vals) => (vals[0] + vals[1]), data[0], data[1]);

    this.consumptionYear = {
      labels: labels,
      data: [consAvgData, ...data],
      datasets: [consAvgDataset, ...datasets],
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("MMM/YY");
                }
              }
            }
          ],

          yAxes: [
            {
              stacked: true,
              id: "kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createFinanceChart = (origin) => {
    let labels = [];
    let data = [[], []];
    let datasets = [];
    let series = ["Consumo", "Produção líquida"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.finance = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  createFinanceMonthChart = (origin) => {
    let labels = [];
    let data = [[], []];
    let datasets = [];
    let series = ["Consumo", "Produção líquida"];
    let colors = ["#AA3033", "#FDA50F"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
      data[1].push(row.production);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.financeMonth = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  // createLast24HrsChart = (origin) => {
  //   let labels = [];
  //   let data = [[], [], []];
  //   let datasets = [];
  //   let series = ["Produção", "Consumo", "Excedente"];
  //   let colors = ["#FDA50F", "#AA3033", "#512da8"];
  //   // let tints = ["#D59899BF", "#FED287BF", "#977ED3BF"];
  //   let transparent = "#00000000";
  //
  //   origin.forEach((row) => {
  //     labels.push(moment(row.date).tz('Etc/GMT+0'));
  //     data[0].push(row.production);
  //     data[1].push(row.consumption);
  //     data[2].push(row.return);
  //   });
  //
  //   data.forEach((r, i) => {
  //     datasets.push({
  //       label: series[i],
  //       data: r,
  //       // backgroundColor: tints[i],
  //       backgroundColor: colors[i],
  //       borderColor: colors[i],
  //       pointBorderColor: transparent,
  //       pointBackgroundColor: transparent,
  //       // pointHoverBackgroundColor: tints[i],
  //       pointHoverBorderColor: transparent,
  //       fill: true
  //     });
  //   });
  //
  //   this.last24hrs = {
  //     labels: labels,
  //     datasets: datasets,
  //     data: data,
  //     series: series,
  //     options: {
  //       tooltips: {
  //         enabled: true,
  //         callbacks: {
  //           label: function (tooltipItem, data) {
  //             let dataset = data.datasets[tooltipItem.datasetIndex];
  //             let value;
  //             if (dataset.label === "Excedente") // Show tooltip for Excedente with positive value even though the actual value is negative
  //               value = -dataset.data[tooltipItem.index];
  //             else
  //               value = dataset.data[tooltipItem.index];
  //             return dataset.label + ": " + value;
  //           }
  //         }
  //       },
  //       legend: {
  //         display: true,
  //         position: 'bottom'
  //       },
  //       animation: false,
  //       maintainAspectRatio: false,
  //       scales: {
  //         xAxes: [
  //           {
  //             stacked: true,
  //             gridLines: {
  //               display: true
  //             },
  //             scaleLabel: {
  //               display: false
  //             },
  //             display: true,
  //             type: "time",
  //             time: {
  //               tooltipFormat: "HH:mm",
  //               unit: "minute",
  //               unitStepSize: 60,
  //               displayFormats: {
  //                 minute: "HH:mm"
  //               }
  //             }
  //           }
  //         ],
  //         yAxes: [
  //           {
  //             stacked: true,
  //             id: "kWh",
  //             gridLines: {
  //               display: false
  //             },
  //             scaleLabel: {
  //               display: false
  //             },
  //             display: true,
  //             ticks: {
  //               beginAtZero: true,
  //               maxTicksLimit: 6,
  //               callback: function (value, index, values) {
  //                 return (Math.round((value + Number.EPSILON) * 100) / 100);
  //               }
  //             }
  //           }
  //         ]
  //       }
  //     }
  //   };
  // };

  // Output array with times from 00:00 to 23:00
  createFullDayLabels = () => {
    let l = [];
    let time = moment.utc('00:00', 'HH:mm');
    for (let i = 0; i < 24; i++) {
      l.push(time.format('HH:mm'));
      time.add(1, 'h');
    }
    return l;
  };

  // Output an object with data for chart and other relevant info for background colors
  createDayData = (origin, colors, tints) => {
    let d = [[], [], []]; // For values and their costs
    let c = [[], [], []];
    let colorIndex = 0;
    for (let i = 0; i < origin.length; i++) {
      let m = moment.utc(origin[i].date);
      d[0][m.hour()] = origin[i].production;
      d[1][m.hour()] = origin[i].consumption;
      d[2][m.hour()] = origin[i].return;

      c[0][m.hour()] = origin[i].costProduction;
      c[1][m.hour()] = origin[i].costConsumption;
      c[2][m.hour()] = origin[i].costReturn;
    }

    // If we don't have data for this hour, use old data
    if (moment().minute() < 15) {
      let m = moment.utc(origin[origin.length - 1].date);
      d[0][m.hour()] = origin[0].production;
      d[1][m.hour()] = origin[0].consumption;
      d[2][m.hour()] = origin[0].return;

      c[0][m.hour()] = origin[0].costProduction;
      c[1][m.hour()] = origin[0].costConsumption;
      c[2][m.hour()] = origin[0].costReturn;
      colorIndex = m.hour();
    } else {
      colorIndex = moment.utc(origin[origin.length - 1].date).hour() + 1;
    }

    let backgroundColors = [[], [], [], []];
    for (let i = 0; i < 24; i++) {
      if (i < colorIndex) {
        backgroundColors[0][i] = colors[0];
        backgroundColors[1][i] = colors[1];
        backgroundColors[2][i] = colors[2];
      } else {
        backgroundColors[0][i] = tints[0];
        backgroundColors[1][i] = tints[1];
        backgroundColors[2][i] = tints[2];
      }
    }

    return {
      data: d,
      cost: c,
      backgroundColors: backgroundColors,
      colorIndex: colorIndex
    }
  };

  // Get aggregate data from the collected data
  getAggregates = (dayData) => {
    // index 0 of data is production, index 2 of data is return
    let indexProduction = 0;
    let indexGrid = 1;
    let indexReturn = 2;
    let dayProduction = 0;
    let dayGrid = 0;
    let dayReturn = 0;
    let costProduction = 0;
    let costGrid = 0;
    let costReturn = 0;

    if (dayData && dayData.data.length === 3 && dayData.colorIndex) {
      // Go through index 0 of data and sum all production and all consumo
      for (let i = 0; i < dayData.colorIndex; i++) {
        dayProduction += dayData.data[indexProduction][i];
        dayGrid += dayData.data[indexGrid][i];
        dayReturn += dayData.data[indexReturn][i];

        costProduction += dayData.cost[indexProduction][i];
        costGrid += dayData.cost[indexGrid][i];
        costReturn += dayData.cost[indexReturn][i];
      }

      // dayData.dayProduction que vem já é potência líquida, temos que somar o retorno para obter o bruto (para total e custo)
      this.totalProduction = Number.parseFloat((dayProduction + Math.abs(dayReturn)).toFixed(3)); // Total production = production liquid + return
      this.totalGrid = Number.parseFloat(dayGrid.toFixed(3));
      this.totalReturn = Number.parseFloat(Math.abs(dayReturn).toFixed(3)); // Use positive value
      this.totalProductionLiquid = Number.parseFloat(dayProduction.toFixed(3));

      this.costProduction = Number.parseFloat((costProduction + costReturn).toFixed(2));
      this.costGrid = Number.parseFloat(costGrid.toFixed(2));
      this.costReturn = Number.parseFloat(costReturn.toFixed(2));
      this.costProductionLiquid = Number.parseFloat(costProduction.toFixed(2));

      if (this.totalProduction > 0) {
        // Find ratio between production and return
        this.selfConsumedSolarEnergyRatio = 100 - (this.totalProductionLiquid / this.totalProduction * 100);
        this.selfSufficiencyRatio = this.totalProductionLiquid / (this.totalProductionLiquid + this.totalGrid) * 100;
        if (this.selfSufficiencyRatio < 0 || this.selfSufficiencyRatio > 100) {
          this.selfSufficiencyRatio = null;
        }
        if (this.selfConsumedSolarEnergyRatio < 0 || this.selfConsumedSolarEnergyRatio > 100) {
          this.selfConsumedSolarEnergyRatio = null;
        }
      } else {
        this.selfConsumedSolarEnergyRatio = 0;
        this.selfSufficiencyRatio = 0;
      }
    }
  };

  createDayChart = (origin) => {
    let max;
    if (origin.length > 0)
      max = origin[0].max || 100;
    let labels = this.createFullDayLabels();
    let datasets = [];
    let series = ["Solar consumido", "Rede", "Excedente solar"];
    let colors = ["#FDA50F", "#AA3033", "#512DA8"];
    let tints = ["#FFE6BDBF", "#EAC5C6BF", "#C4B5E9BF"];
    let transparent = "#00000000";

    let dayData = this.createDayData(origin, colors, tints);
    let data = dayData.data;

    //replace previous day values with 0 and flip array so they are at the start and ignored by 'createAvgData'
    const actualValuesSolar = Array.from(dayData.data[0]).fill(0, dayData.colorIndex).reverse();
    const actualValuesRede = Array.from(dayData.data[1]).fill(0, dayData.colorIndex).reverse();
    const {data: consAvgData, dataset: consAvgDataset} = this.createAvgData([212, 107, 33], {
      label: 'Média Consumo',
    }, (v) => (v[0] + v[1]), actualValuesSolar, actualValuesRede);

    // Set self consumed solar energy data
    this.getAggregates(dayData);

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        // backgroundColor: tints[i],
        backgroundColor: dayData.backgroundColors[i],
        borderColor: dayData.backgroundColors[i],
        pointBorderColor: transparent,
        pointBackgroundColor: transparent,
        // pointHoverBackgroundColor: tints[i],
        pointHoverBorderColor: transparent,
        fill: true
      });
    });

    this.last24hrs = {
      labels: labels,
      data: [consAvgData, ...data],
      datasets: [consAvgDataset, ...datasets],
      series: series,
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 20,
            right: 20
          }
        },
        cornerRadius: 10,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let value;
              if (dataset.label === "Excedente") // Show tooltip for Excedente with positive value even though the actual value is negative
                value = -dataset.data[tooltipItem.index];
              else
                value = dataset.data[tooltipItem.index];
              return dataset.label + ": " + value;
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                display: true,
                // TODO - Fix to properly use the chart options instead of this
                callback: function(value, index, ticks) {
                  if (index % 3 === 0 || index === ticks.length - 1)
                    return value;
                  else return '';
                }
              },
            }
          ],
          yAxes: [
            {
              stacked: true,
              id: "kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 9,
                max: max,
                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

}

DashboardEnergyController.$inject = ["DashboardService", "$interval", "$scope", "$state", "AlarmService", "$http", 'AuthenticationService'];
