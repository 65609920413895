import {
  routes
} from './routes';
import SiteService from './service';
import SiteListController from './list/controller';
import SiteDetailsController from './details/controller';
import SiteTVController from './tv/controller';
import SiteMapController from './map/controller';

export default angular.module('app.site', [])
  .config(routes)
  .service('SiteService', SiteService)
  .controller('SiteListController', SiteListController)
  .controller('SiteDetailsController', SiteDetailsController)
  .controller('SiteTVController', SiteTVController)
  .controller('SiteMapController', SiteMapController)
  .name;
