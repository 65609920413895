const moment = require("moment-timezone");
export default class DashboardWaterController {
  constructor(DashboardWater, $interval, $scope, $state) {
    this.DashboardWater = DashboardWater;
    this.counters = {};

    this.getData();

    if (!$scope.$$destroyed) {
      this.interval = $interval(() => {
        this.getData();
      }, 2 * 1000 * 60);
    }

    $scope.$on("$destroy", () => {
      if (this.interval) $interval.cancel(this.interval);
    });
  }

  getData = () => {
    this.consumptionLoaded = false;
    // Water Consumption - using measureId
    this.DashboardWater.dashboardConsumption('60acdc530b903917588ec45a').then((r) => {
      this.createConsumptionChart(r);
      this.consumptionLoaded = true;
    });

    this.financeLoaded = false;
    this.DashboardWater.dashboardWaterFinance().then((r) => {
      this.createFinanceChart(r);
      this.financeLoaded = true;
    });

    this.consumptionMonthLoaded = false;
    // Water Consumption - using measureId
    this.DashboardWater.dashboardConsumption('60acdc530b903917588ec45a', true).then((r) => {
      this.createConsumptionMonthChart(r);
      this.consumptionMonthLoaded = true;
    });

    this.financeMonthLoaded = false;
    this.DashboardWater.dashboardWaterFinance(true).then((r) => {
      this.createFinanceMonthChart(r);
      this.financeMonthLoaded = true;
    });

    this.consumptionYearLoaded = false;
    this.DashboardWater.dashboardConsumptionYear('60acdc530b903917588ec45a').then((r) => {
      this.createConsumptionYearChart(r);
      this.consumptionYearLoaded = true;
    });

    this.financeYearLoaded = false;
    this.DashboardWater.dashboardWaterFinanceYear().then((r) => {
      this.createFinanceYearChart(r);
      this.financeYearLoaded = true;
    });
  };

  createConsumptionChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[]];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.consumption = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionMonthChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[]];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.consumptionMonth = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionYearChart = (origin) => {
    let labels = [];
    let datasets = [];
    let data = [[]];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.consumptionYear = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("MMM/YY");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 5,

                beginAtZero: true,
                callback: function (value, index, values) {
                  return value;
                }
              }
            }
          ]
        }
      }
    };
  };

  createFinanceChart = (origin) => {
    let labels = [];
    let data = [[]];
    let datasets = [];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.finance = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  createFinanceMonthChart = (origin) => {
    let labels = [];
    let data = [[]];
    let datasets = [];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.financeMonth = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };
  createFinanceYearChart = (origin) => {
    let labels = [];
    let data = [[]];
    let datasets = [];
    let series = ["Consumo"];
    let colors = ["#0f53ba"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.consumption);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: colors[i],
        borderColor: colors[i]
      });
    });

    this.financeYear = {
      labels: labels,
      data: data,
      series: series,
      datasets: datasets,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("MMM/YY");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "€",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };
}

DashboardWaterController.$inject = ["DashboardService", "$interval", "$scope", "$state"];
