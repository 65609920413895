const AdaptiveDashboardWidget = require('./widget');

const periodsData = {
  '3hours': {
    granularity: 'minute',
    xAxisLabel: (date) => date.format("H:mm"),
    getFormatedDate: (date) => {
      if (date.isSame(moment(), 'day'))
        return "Hoje";
      return date.format('DD/MM/YYYY');
    },
    operations: [{amount: 3, unit: 'hour'}, {amount: 1, unit: 'day'}],
    postOperation: (oldDate, newDate) => {
      if (!oldDate.isSame(newDate, 'day') && oldDate > newDate)
        newDate.endOf('day');
    }
  },
  'day': {
    granularity: 'hour',
    xAxisLabel: (date) => date.format("H:mm"),
    getFormatedDate: (date) => {
      if (date.isSame(moment(), 'day'))
        return "Hoje";
      return date.format('DD/MM/YYYY');
    },
    operations: [{amount: 1, unit: 'day'}, {amount: 1, unit: 'week'}],
    postOperation: (_, newDate) => {
      newDate.endOf('day');
    }
  },
  'month': {
    granularity: 'day',
    xAxisLabel: (date) => date.format("D"),
    getFormatedDate: (date) => {
      if (date.isSame(moment(), 'month'))
        return "Este mês";
      return date.format('MMMM/YYYY');
    },
    operations: [{amount: 1, unit: 'month'}, {amount: 1, unit: 'quarter'}],
    postOperation: (_, newDate) => {
      newDate.endOf('month');
    }
  },
  'year': {
    granularity: 'month',
    xAxisLabel: (date) => date.format("MMM"),
    getFormatedDate: (date) => {
      if (date.isSame(moment(), 'year'))
        return "Este ano";
      return date.format('YYYY');
    },
    operations: [{amount: 1, unit: 'year'}, {amount: 2, unit: 'year'}],
    postOperation: (_, newDate) => {
      newDate.endOf('year');
    }
  },
}

class AdaptiveDashboardChart extends AdaptiveDashboardWidget {
  constructor(widget, AdaptiveDashboardService, $timeout) {
    super(widget, AdaptiveDashboardService, $timeout);
    this.periodData = periodsData[this.widget.period];
    if (!this.periodData)
      throw new Error("Invalid period");

    this.loadData();
  }

  getControlLabel = (direction, operationIndex) => {
    if (operationIndex >= this.periodData.operations.length)
      throw new Error("Invalid operationIndex");

    const timeUnit = this.periodData.operations[operationIndex].unit;
    const amount = this.periodData.operations[operationIndex].amount;
    switch (direction) {
      case 'backwards':
        return `Voltar ${amount} ${this.timeUnitConversion(timeUnit, amount)}`;
      case 'fowards':
        return `Avançar ${amount} ${this.timeUnitConversion(timeUnit, amount)}`;
      default:
        throw new Error("Invalid direction");
    }
  }

  timeUnitConversion = (timeUnit, amount) => {
    switch (timeUnit) {
      case 'minute':
      case 'minutes':
        return amount == 1 ? 'Minuto' : 'Minutos';
      case 'hour':
      case 'hours':
        return amount == 1 ? 'Hora' : 'Horas';
      case 'day':
      case 'days':
        return amount == 1 ? 'Dia' : 'Dias';
      case 'month':
      case 'months':
        return amount == 1 ? 'Mês' : 'Meses';
      case 'quarter':
      case 'quarters':
        return amount == 1 ? 'Trimestre' : 'Trimestres';
      case 'year':
      case 'years':
        return amount == 1 ? 'Ano' : 'Anos';
      default:
        throw new Error("Invalid time unit");
    }
  }

  roundValue = (value, decimals) => {
    if (value <= 0)
      return value;
    if (value >= 1)
      return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    const zeros = Math.ceil(Math.log10(value)) * -1 + decimals;
    return Math.round(value * Math.pow(10, zeros)) / Math.pow(10, zeros);
  }

  getGranularity = () => this.periodData.granularity;
  getXaxisLabel = (moment) => this.periodData.xAxisLabel(moment);

  getDataRange = (startingDate) => {
    const to = moment(startingDate);
    const from = moment(startingDate);
    switch (this.widget.period) {
      case '3hours':
        if (from.hour() < 3)
          from.startOf('day');
        else
          from.subtract(3, 'hours');
        break;
      case 'day':
        from.startOf('day');
        break;
      case 'month':
        from.startOf('month');
        break;
      case 'year':
        from.startOf('year');
        break;

      default:
        throw new Error("Invalid period");
    }
    return {from, to};
  }

  moveChart = (direction, operationIndex) => {
    if (operationIndex >= this.periodData.operations.length)
      throw new Error("Invalid operationIndex");

    const timeUnit = this.periodData.operations[operationIndex].unit;
    const time = this.periodData.operations[operationIndex].amount;
    const postOperation = this.periodData.postOperation;

    const oldDate = moment(this.date);
    switch (direction) {
      case 'backwards':
        this.date.subtract(time, timeUnit);
        if (postOperation)
          postOperation(oldDate, this.date);
        break;
      case 'fowards':
        this.date.add(time, timeUnit);
        if (postOperation)
          postOperation(oldDate, this.date);
        if (this.date > moment())
          this.date = moment();
        break;
      default:
        throw new Error("Invalid direction");
    }
    this.loadData();
  }

  getDate = () => this.periodData.getFormatedDate(this.date);

  getConvertedValue = (value) => {
    const callback = this.getOverride("convertValue", (v) => (v))
    return callback(value);
  }

  loadData = () => {
    const granularity = this.getGranularity();
    const {from, to} = this.getDataRange(this.date);
    //TODO save data and reuse data (mostly for 3hrs period, since it fetches the whole day either way)
    this.Service.getChartData(granularity, this.widget.measureId, this.widget.smartmeterIds, from.toISOString(), to.toISOString())
    .then(r => {
      this.$timeout(() => {
        this.generateChart(r);
        this.widget.loaded = true;
      });
    })
    .catch(e => console.error(e));
  }

  getLabels = () => {
    const granularity = this.getGranularity();
    const {from, to} = this.getDataRange(this.date);
    const labels = [];
    while (from.isBefore(to)) {
      labels.push(from.toISOString());
      from.add(1, granularity);
    }
    return labels;
  }


  generateChart = (meterData) => {

    let labels = this.getLabels();
    let datasets = [];
    let data = meterData.map((m) => {
      if (labels.length == m.registers.length) {
        const tempdata = m.registers.map((r) => this.roundValue(this.getConvertedValue(r.converted || 0), 2))
        return tempdata;

      }

      let ret = [];
      const registers = m.registers.map((r) => Object.assign({}, r, {date: moment(r.date)}));
      const momentLabels = labels.map((l) => moment(l));
      for (let i = 0; i < labels.length; i++) {
        const labelDate = momentLabels[i];
        const i = registers.findIndex((r) => r.date.isSame(labelDate, this.getGranularity()));
        if (i >= 0) {
          const r = registers[i];
          ret.push(this.roundValue(this.getConvertedValue(r.converted || 0), 2));
          registers.splice(0, i);
        } else
          ret.push(0);
      }
      return ret;

    });
    let series = meterData.map((m) => m.name);

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        pointRadius: 0,
        pointHitRadius: 5,
      });
    });

    this.widget.chart = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                maxTicksLimit: 20,
                callback: function (value, index, values) {
                  return this.getXaxisLabel(moment(value));
                }.bind(this)
              }
            }
          ],

          yAxes: [
            {
              id: "m3",
              gridLines: {
                display: true,
              },
              scaleLabel: {
                display: false
              },

              display: true,
              ticks: {
                maxTicksLimit: 5,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return this.roundValue(value, 2);
                }.bind(this)
              }
            }
          ]
        }
      }
    };
  }
}
module.exports = AdaptiveDashboardChart;
