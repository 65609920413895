import moment from "moment";

export default class DashboardService {
  constructor($q, $http, $rootScope, Aggregator, Smartmeter, Site, Register, RegisterHour, Auth, Authorization, RegisterMinute, Tariffcycle, VariacaoConsumoAnual, PotPontasAvg, PotMonthTotal, PredictionYear, VariacaoConsumoAnualfinalmes, MetaReducaoAnual, MetaReducaoAnualAnt) {
    this.$q = $q;
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.Aggregator = Aggregator;
    this.Site = Site;
    this.Register = Register;
    this.Smartmeter = Smartmeter;
    this.RegisterHour = RegisterHour;
    this.RegisterMinute = RegisterMinute;
    this.Auth = Auth;
    this.Authorization = Authorization;
    this.Tariffcycle = Tariffcycle;
    this.VariacaoConsumoAnual = VariacaoConsumoAnual;
    this.VariacaoConsumoAnualfinalmes = VariacaoConsumoAnualfinalmes;
    this.PotPontasAvg = PotPontasAvg;
    this.PotMonthTotal = PotMonthTotal;
    this.PredictionYear = PredictionYear;
    this.MetaReducaoAnual = MetaReducaoAnual;
    this.MetaReducaoAnualAnt = MetaReducaoAnualAnt;
    this.weatherCodes = [
      // Trovoada
      { id: 200, value: "thunderstorms-{type}-rain", text: "Trovoada com chuva fraca" },
      { id: 201, value: "thunderstorms-{type}-rain", text: "Trovada com chuva" },
      { id: 202, value: "thunderstorms-{type}-rain", text: "Trovada com chuva moderada" },
      { id: 210, value: "thunderstorms-{type}", text: "Trovoada leve" },
      { id: 211, value: "thunderstorms-{type}", text: "Trovoada" },
      { id: 212, value: "thunderstorms", text: "Tempestade" },
      { id: 221, value: "thunderstorms", text: "Tempestade forte" },
      { id: 222, value: "thunderstorms", text: "Tempestade forte" },
      { id: 230, value: "thunderstorms-{type}-rain", text: "Trovoada" },
      { id: 231, value: "thunderstorms-{type}-rain", text: "Trovoada" },
      { id: 232, value: "thunderstorms-{type}-rain", text: "Trovoada com chuva moderada" },
      // Chuviscos
      { id: 300, value: "partly-cloudy-{type}-drizzle", text: "Chuviscos" },
      { id: 301, value: "partly-cloudy-{type}-drizzle", text: "Chuviscos" },
      { id: 302, value: "partly-cloudy-{type}-drizzle", text: "Chuviscos" },
      { id: 310, value: "partly-cloudy-{type}-drizzle", text: "Chuviscos" },
      { id: 311, value: "drizzle", text: "Chuviscos" },
      { id: 312, value: "drizzle", text: "Chuviscos" },
      { id: 313, value: "drizzle", text: "Chuva" },
      { id: 314, value: "drizzle", text: "Chuva moderada" },
      { id: 321, value: "drizzle", text: "Chuviscos" },
      // Chuva
      { id: 500, value: "partly-cloudy-{type}-drizzle", text: "Chuva leve" },
      { id: 501, value: "partly-cloudy-{type}-rain", text: "Chuva moderada" },
      { id: 502, value: "partly-cloudy-{type}-rain", text: "Chuva de forte intensidade" },
      { id: 503, value: "partly-cloudy-{type}-rain", text: "Chuva muito forte" },
      { id: 504, value: "partly-cloudy-{type}-rain", text: "Chuva extrema" },
      { id: 511, value: "rain", text: "Chuva" },
      { id: 520, value: "rain", text: "Chuva" },
      { id: 521, value: "rain", text: "Chuva" },
      { id: 522, value: "rain", text: "Chuva" },
      { id: 531, value: "rain", text: "Chuva" },
      // Neve
      { id: 600, value: "partly-cloudy-{type}-snow", text: "Neve" },
      { id: 601, value: "partly-cloudy-{type}-snow", text: "Neve" },
      { id: 602, value: "partly-cloudy-{type}-snow", text: "Neve" },
      { id: 611, value: "partly-cloudy-{type}-sleet", text: "Granizo" },
      { id: 612, value: "partly-cloudy-{type}-sleet", text: "Neve" },
      { id: 613, value: "partly-cloudy-{type}-sleet", text: "Neve" },
      { id: 615, value: "partly-cloudy-{type}-sleet", text: "Neve" },
      { id: 616, value: "partly-cloudy-{type}-sleet", text: "Neve" },
      { id: 620, value: "partly-cloudy-{type}-sleet", text: "Neve" },
      { id: 621, value: "partly-cloudy-{type}-sleet", text: "Neve" },
      { id: 622, value: "snow" },
      // Nevoeiro e afins
      { id: 701, value: "mist", text: "Nevoeiro" },
      { id: 711, value: "mist", text: "Nevoeiro" },
      { id: 721, value: "mist", text: "Nevoeiro" },
      { id: 731, value: "mist" },
      { id: 741, value: "fog-{type}" },
      { id: 751, value: "fog-{type}" },
      { id: 761, value: "fog-{type}" },
      { id: 762, value: "fog-{type}" },
      { id: 771, value: "fog-{type}" },
      { id: 781, value: "tornado", text: "Tornado" },
      // Céu limpo
      { id: 800, value: "clear-{type}", text: "Céu limpo" },
      // Nublado
      { id: 801, value: "partly-cloudy-{type}", text: "Parcialmente nublado" },
      { id: 802, value: "partly-cloudy-{type}", text: "Parcialmente nublado" },
      { id: 803, value: "overcast-{type}", text: "Nublado" },
      { id: 804, value: "overcast-{type}", text: "Nublado" }
    ];
  };

  getPhoto = (fileObject) => {
    let defer = this.$q.defer();
    this.$http.get(`api/assets/containers/${fileObject.container}/files/${fileObject.filename}/download`, { responseType: 'blob' })
      .then((r) => defer.resolve(r))
      .catch((err) => defer.reject(err));
    return defer.promise;
  };

  getInfo = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    let siteId = user.selected.siteId;
    this.Site.findById({ id: siteId, filter: { include: ['solarInstaller'] } }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  hasMeasure = (measureId) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Smartmeter.count({
      where: {
        measureId: measureId,
        siteId: { inq: user.siteIds }
      }
    }).$promise.then(r => defer.resolve(r.count > 0)).catch(e => defer.resolve(false));
    return defer.promise;
  };

  registeredGateways = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Aggregator.find({
      filter: {
        where: {
          siteId: { inq: user.siteIds },
        },
        fields: {
          id: true,
          active: true
        },
        include: 'smartmeters'
      },
    }).$promise.then((r) => {
      let smartmeters = [];
      for (let o of r) {
        smartmeters.push(...o.smartmeters);
      }
      smartmeters = smartmeters.filter(r => r.active);
      let ok = smartmeters.filter(r => !r.error || r.error === false);
      defer.resolve({
        gateways: r.length,
        smartmeters: {
          ok: ok.length,
          error: smartmeters.length - ok.length,
          total: smartmeters.length
        }
      });
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  registeredSites = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.count({
      where: {
        entityId: { inq: user.entityIds }
      }
    }).$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

  registeredPotency = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Site.find({
      filter: {
        where: {
          id: user.selected.siteId
        }
      }
    }).$promise.then((r) => {
      defer.resolve(r.filter(x => x.potency));
    }).catch((e) => defer.reject(e));

    return defer.promise;
  };

  getTariffCycle = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Tariffcycle.findOne({
      filter: {
        where: {
          entityId: user.selected.entityId
        }
      }
    }).$promise.then((tariffCycle) => {
      defer.resolve(tariffCycle);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  };

  getVariacaoConsumoAnual = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.VariacaoConsumoAnual.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  getVariacaoConsumoAnualFinal = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.VariacaoConsumoAnualfinalmes.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  getPeakAverage = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.PotPontasAvg.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  getMonthTotal = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.PotMonthTotal.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  getPredictYear = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.PredictionYear.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  getMetaReducaoAnual = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.MetaReducaoAnual.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  getMetaReducaoAnualAnt = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.MetaReducaoAnualAnt.find({
      filter: {
        where: {
          siteId: user.selected.siteId
        }
      }
    }).$promise.then((variacao) => {
      defer.resolve(variacao);
    }).catch((error) => {
      defer.reject(error);
    });
    
    return defer.promise;
  }

  last24hrs = (date) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.RegisterHour.dashboard24hrs({
      siteId: [user.selected.siteId],
      date: moment.utc(date).toISOString() || moment.utc().toISOString(),
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  waterLast24hrs = (date) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.RegisterHour.dashboardWater24hrs({
      siteId: [user.selected.siteId],
      date: moment.utc(date).toISOString() || moment.utc().toISOString(),
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardConsumption = (measureId, lastMonth, liquidProduction) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardConsumption({
      siteId: [user.selected.siteId],
      measureId: measureId,
      lastMonth: lastMonth || false,
      liquidProduction: liquidProduction || false
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardConsumptionYear = (measureId, liquidProduction) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardConsumptionYear({
      siteId: [user.selected.siteId],
      measureId: measureId,
      liquidProduction: liquidProduction || false
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardFinance = (lastMonth) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardFinance({
      siteId: [user.selected.siteId],
      entityId: [user.entityIds],
      lastMonth: lastMonth || false
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardWaterFinance = (lastMonth) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardWaterFinance({
      siteId: [user.selected.siteId],
      entityId: [user.entityIds],
      lastMonth: lastMonth || false
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardWaterFinanceYear = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardWaterFinanceYear({
      siteId: [user.selected.siteId],
      entityId: [user.entityIds],
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardCO2 = (lastMonth) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardCO2({ //should work
      siteId: [user.selected.siteId],
      lastMonth: lastMonth || false
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardCO2Year = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardCO2Year({
      siteId: [user.selected.siteId],
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  dashboardDoors = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardDoors({
      siteId: [user.selected.siteId],
    }).$promise.then((c) => {
      defer.resolve(c);
    }).catch((e) => defer.reject(e));
    return defer.promise;
  };

  getOpenEnergyData = (feedId) => {
    let defer = this.$q.defer();
    this.Site.openEnergyData({ no: feedId }).$promise.then(response => {
      defer.resolve(response);
    }).catch(err => defer.reject(err));
    return defer.promise;
  };

  getFinanceMetrics = (measure) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.financeMetrics({
      siteId: [user.selected.siteId],
      measure: measure
    }).$promise.then((c) => defer.resolve(c)).catch((e) => defer.reject(e));
    return defer.promise;
  };

  weatherIconGenerator = code => {
    let group = this.weatherCodes.find(r => r.id == code);
    // Verifica se é de dia ou noite
    let today = moment.utc();
    const hours = today.hour();
    const isDay = hours >= 6 && hours < (today.isDST() ? 20 : 18);
    if (group) {
      return group.value.replace("{type}", isDay ? "day" : "night")
    } else {
      return "cloudy";
    }
  };

  weatherTextGenerator = code => {
    let group = this.weatherCodes.find(r => r.id == code);
    return group.text;
  };

  getOutdoorTemperature = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardOutdoorTemperature({
      siteId: user.selected.siteId
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getIndoorTemperature = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardIndoorTemperature({
      siteId: user.selected.siteId
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getSolarPeaks = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dashboardSolarPeaks({
      siteId: user.selected.siteId
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  solarData = () => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.solarData({
      siteId: user.selected.siteId
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };


  getDayProduction = (date) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.dayProduction({
      siteId: user.selected.siteId,
      date: moment.utc(date).toISOString() || moment.utc().toISOString(),
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getSolarBreakeven = (date) => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Register.solarBreakeven({
      siteId: user.selected.siteId,
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getSmartmeters = measure => {
    let defer = this.$q.defer();
    let user = this.Auth.getUser();
    this.Smartmeter.find({
      filter: {
        where: {
          active: true,
          siteId: user.selected.siteId,
          measureId: measure
        }
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getSmartmeter24hrs = (id, date, unit) => {
    let defer = this.$q.defer();
    this.Smartmeter.data24hrs({id: id, date: date, unit: unit}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  getSmartmeterWeekData = (id, date) => {
    let defer = this.$q.defer();
    this.Register.chart1hour({smartmeter: id, from: moment(date).startOf('week'), to: moment(date)}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  trigger = (id, feature) => {
    let defer = this.$q.defer();
    this.Smartmeter.trigger({id: id, feature: feature}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  state = (id, feature) => {
    let defer = this.$q.defer();
    this.Smartmeter.state({id: id, feature: feature}).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  consumption3h = (from, to, smartmeters, filterEnergy, filterWater, isFeature) => {
    let defer = this.$q.defer();
    this.RegisterMinute.analyticsConsumption3h({
      from: from,
      to: to,
      smartmeters: smartmeters,
      filterEnergy: filterEnergy,
      filterWater: filterWater,
      isFeature: isFeature || false
    }).$promise.then((r) => defer.resolve(r))
      .catch((e) => defer.reject(e));
    return defer.promise;
  };

}

DashboardService.$inject = ["$q", "$http", "$rootScope", "Aggregator", "Smartmeter", "Site", "Register", "RegisterHour", "AuthenticationService", "AuthorizationService", "RegisterMinute", "Tariffcycle", "VariacaoConsumoAnual", "PotPontasAvg", "PotMonthTotal", "PredictionYear", "VariacaoConsumoAnualfinalmes", "MetaReducaoAnual", "MetaReducaoAnualAnt"];
