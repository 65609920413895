export default class AuthorizationService {
  constructor($rootScope, Entity, Site, $q, Rolemap, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.Rolemap = Rolemap;
    this.AuthenticationService = AuthenticationService;
    this.Entity = Entity;
    this.Site = Site;
    this.$q = $q;
    this.roles = [];
    this.entities = [];
    this.sites = [];
    this.groups = [];

    $rootScope.$on("$stateChangeStart", () => {
      if (
        AuthenticationService.isAuthenticated() &&
        AuthenticationService.getUser().hasOwnProperty("email")
      ) {
        this.loadACLs();
        this.loadEntities();
      }
    });

    if (
      AuthenticationService.isAuthenticated() &&
      AuthenticationService.getUser().hasOwnProperty("email")
    ) {
      this.loadACLs();
      this.loadEntities();
    }
  }

  loadEntities = () => {
    let user = this.AuthenticationService.getUser();
    let query = {
      filter: {
        where: {
          active: true,
        },
        fields: {
          id: true,
          name: true,
        },
        include: {
          relation: 'sites',
          scope: {
            fields: {
              id: true,
              name: true,
              location: true,
              entityId: true
            }
          }
        }
      },
    }
    this.Entity.find(query).$promise.then((r) => {
      user = this.AuthenticationService.getUser();
      if (!user.entities.length) {
        // Force user to have first entity
        user.entityIds = [r[0].id];
        user.entities = [r[0]];
        user.siteIds = [user.entities.sites[0].id];
        user.sites = [user.entities.sites[0]];
      }
      this.entities = r;
    });
  };

  getAllowed = () => {
    return this.roles;
  };

  getEntities = () => {
    return this.entities;
  };

  // Obtem lista de todas as funcionalidades presentes nas roles associadas ao user...
  loadACLs = () => {
    const user = this.AuthenticationService.getUser();
    if (_.isEmpty(user)) return;
    let k = [];
    let j = [];
    user.groups = user.groups || [];
    user.groups.forEach((g) => {
      j.push(g.code);
      g.rolegroup.forEach((r) => {
        if (r.active === true) k.push(r.role.name);
      });
    });
    // Important, otherwise we will have an exception in canPerform...
    k.push("$authenticated");
    k.push("tariffList"); k.push("tariffEdit");
    this.roles = _.uniqBy(k);
    this.groups = _.uniqWith(j, _.isEqual);
  };

  isAuthenticated = () => {
    return (
      this.AuthenticationService.isAuthenticated() && this.roles.length > 0
    );
  };

  // Verifica se o utilizador pode executar conjunto de roles...
  canPerform = (roles) => {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }
    let obj = {};
    this.roles.forEach((el, index) => {
      obj[el] = index;
    });
    return roles.every((el) => obj[el] !== undefined);
  };

  // Verifica se o utilizador pertence a um(uns) grupo(s)
  belongsTo = (group) => {
    if (!Array.isArray(group)) {
      group = [group];
    }
    let obj = {};
    this.groups.forEach((el, index) => {
      obj[el] = index;
    });
    return group.every((el) => obj[el] !== undefined);
  };

  isAdmin = () => {
    return this.roles.includes("controlPanel");
  }
}

AuthorizationService.$inject = [
  "$rootScope",
  "Entity",
  "Site",
  "$q",
  "Rolemap",
  "AuthenticationService",
];
