import React, { useEffect, useState } from "react";
import CurrentVariation from "./CurrentVariation";
import { Box, Grid } from "@mui/material";
import AccumulatedVariation from "./AccumulatedVariation";
const GetVariationData = ({ DashboardService }) => {
    const [currentVariationData, setCurrentVariationData] = useState([]);
    const [variationDataFinal, setVariationDataFinal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingYearData, setLoadingYearData] = useState(true);
    useEffect(() => {
        if (currentVariationData.length === 0 || variationDataFinal.length === 0) {
            getCurrentVariationData();
        }
    }, [currentVariationData, variationDataFinal]);
    const getCurrentVariationData = async () => {
        if (currentVariationData.length === 0) {
            try {
                const variationData = await DashboardService.getVariacaoConsumoAnual();
                if (variationData.length !== 0) {
                    const groupedData = variationData.reduce((acc, resource) => {
                        const date = new Date(resource.date);
                        const month = date.getMonth() + 1; // getMonth() retorna 0-11
                        const year = date.getFullYear();
                        const key = `${year}-${month.toString().padStart(2, "0")}`; // Formato YYYY-MM
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(resource);
                        return acc;
                    }, {});
                    // Converte o objeto em um array
                    const dayDataArray = Object.keys(groupedData).map((key) => ({
                        month: key,
                        resources: groupedData[key],
                    }));
                    const reverseData = dayDataArray.reverse();
                    setCurrentVariationData(reverseData);
                }
                setLoading(false);
            }
            catch (error) {
                console.error("Erro ao carregar consumos:", error.message);
                setLoading(false);
            }
        }
        if (variationDataFinal.length === 0) {
            try {
                const data = await DashboardService.getVariacaoConsumoAnualFinal();
                if (data.length !== 0) {
                    const groupedData = data.reduce((acc, resource) => {
                        const date = new Date(resource.date);
                        const month = date.getMonth() + 1; // getMonth() retorna 0-11
                        const year = date.getFullYear();
                        const key = `${year}-${month.toString().padStart(2, "0")}`; // Formato YYYY-MM
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(resource);
                        return acc;
                    }, {});
                    // Converte o objeto em um array
                    const dayDataArray = Object.keys(groupedData).map((key) => ({
                        month: key,
                        resources: groupedData[key],
                    }));
                    // Transform the reverseData to the desired format
                    const reverseData = dayDataArray.map((item) => ({
                        month: item.month,
                        variacaoAcumulada: item.resources[0].variacaoAcumulada,
                        variacaoAcumuladaAno: item.resources[0].variacaoAcumuladaAno,
                        variacaoCustoAcumulada: item.resources[0].variacaoCustoAcumulada,
                        variacaoCustoAcumuladaAno: item.resources[0].variacaoCustoAcumuladaAno,
                    }));
                    // Group by year
                    const groupedByYear = reverseData.reduce((acc, item) => {
                        const year = item.month.split("-")[0];
                        if (!acc[year]) {
                            acc[year] = [];
                        }
                        acc[year].push(item);
                        return acc;
                    }, {});
                    // Convert the object into an array of arrays
                    const finalDataByYear = Object.values(groupedByYear);
                    setVariationDataFinal(finalDataByYear.reverse());
                }
                setLoadingYearData(false);
            }
            catch (error) {
                console.error("Erro ao carregar consumos anuais:", error.message);
                setLoadingYearData(false);
            }
        }
    };
    return (<Box sx={{ mb: 2, width: "100%" }}>
      {loading || loadingYearData ? (<Box></Box>) : (<Box>
          <Grid container spacing={2}>
            <Grid item>
              <CurrentVariation values={currentVariationData}/>
            </Grid>
            <Grid item>
              <AccumulatedVariation values={currentVariationData} finalValues={variationDataFinal}/>
            </Grid>
          </Grid>
        </Box>)}
    </Box>);
};
export default GetVariationData;
