import React, { useState } from "react";
import { Button, Card, Stack, IconButton, TextField, ThemeProvider, Checkbox, FormGroup, FormControlLabel, Grid, Alert, } from "@mui/material";
import { theme } from "../../wizard/utils";
import Icon from "@mdi/react";
import { mdiEyeOff, mdiEye } from "@mdi/js";
import SnackbarComponent from "../../hooks/utils/Snackbar";
const UserAdd = ({ $state, AdministrationService }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(false);
    const [alert, setAlert] = useState([]);
    const [open, setOpen] = useState(false);
    const validate = {
        name: false,
        email: false,
        password: false,
    };
    const user = {
        imap: false,
        email: "",
        name: "",
        password: "",
    };
    const handleCheckbox = (event) => {
        setChecked(event.target.checked);
    };
    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const randomizePassword = () => {
        let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        let pass = "";
        for (let x = 0; x < 12; x++) {
            let i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        setPassword(pass);
    };
    const validateData = () => {
        if (email.length === 0) {
            validate.email = false;
            setAlert((alert) => [
                ...alert,
                { type: "email", severity: "error", message: "Email obrigatório." },
            ]);
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            validate.email = false;
            // regex email validation
            setAlert((alert) => [
                ...alert,
                { type: "email", severity: "warning", message: "Email inválido." },
            ]);
        }
        else {
            validate.email = true;
            user.email = email;
        }
        if (name.length === 0) {
            validate.name = false;
            setAlert((alert) => [
                ...alert,
                { type: "name", severity: "error", message: "Nome obrigatório." },
            ]);
        }
        else {
            validate.name = true;
            user.name = name;
        }
        if (password.length === 0) {
            validate.password = false;
            setAlert((alert) => [
                ...alert,
                {
                    type: "password",
                    severity: "error",
                    message: "Password obrigatória.",
                },
            ]);
        }
        else {
            validate.password = true;
            user.password = password;
        }
    };
    const add = async () => {
        setAlert([]);
        validateData();
        if (validate.name && validate.email && validate.password) {
            try {
                await AdministrationService.createUser(user);
                setOpen(true);
                setTimeout(() => $state.go("app.administration.mainV2"), 1500);
            }
            catch (error) {
                console.error("Erro ao criar utilizador:", error);
            }
        }
    };
    return (<ThemeProvider theme={theme}>
      <Card sx={{
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
        }}>
        <Stack spacing={1}>
          <TextField id="name" label="Nome" variant="standard" value={name} onChange={(e) => setName(e.target.value)} fullWidth required/>
          <TextField id="email" label="Email" variant="standard" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth required/>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <TextField id="password" label="Password" type={showPassword ? "text" : "password"} variant="standard" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth required/>
              </Grid>
              <Grid item xs={6} sm={1}>
                <IconButton edge="end" onClick={handlePasswordVisibility}>
                  {showPassword ? (<Icon path={mdiEye} size={1.1}/>) : (<Icon path={mdiEyeOff} size={1.1}/>)}
                </IconButton>
              </Grid>
              <Grid item xs={6} sm={2} justifyContent="flex-start">
                <Button onClick={() => randomizePassword()}>
                  Gerar Password
                </Button>
              </Grid>
            </Grid>
          </div>

          <div>
            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
              <Button variant="contained" onClick={add} type="submit" sx={{ color: "white" }}>
                Adicionar
              </Button>
              <FormGroup>
                <FormControlLabel control={<Checkbox onChange={handleCheckbox}/>} label="Enviar e-mail com dados de conta"/>
              </FormGroup>
            </Stack>
          </div>
        </Stack>
        <Stack spacing={1}>
          {alert.map((a, index) => {
            if (a.type === "email" && a.severity && a.message) {
                return (<Alert key={index} severity={a.severity}>
                  {a.message}
                </Alert>);
            }
            else if (a.type === "name" && a.severity && a.message) {
                return (<Alert key={index} severity={a.severity}>
                  {a.message}
                </Alert>);
            }
            else if (a.type === "password" && a.severity && a.message) {
                return (<Alert key={index} severity={a.severity}>
                  {a.message}
                </Alert>);
            }
        })}
        </Stack>
      </Card>

      <SnackbarComponent message={"Utilizador adicionado!"} open={open} severity={"success"}/>
    </ThemeProvider>);
};
export default UserAdd;
