import {
  routes
} from './routes';
import DeviceService from './service';
import DeviceListController from './list/controller';
import DeviceDetailsController from './details/controller';

export default angular.module('app.device', [])
  .config(routes)
  .service('DeviceService', DeviceService)
  .controller('DeviceListController', DeviceListController)
  .controller('DeviceDetailsController', DeviceDetailsController)
  .name;
