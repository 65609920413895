export default class EvProfileListController {
  constructor(AdministrationService) {
    this.Admin = AdministrationService;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Admin.getEvProfiles().then(r => {
      this.loaded = true;
      this.data = r;
    });
  }
}

EvProfileListController.$inject = ['AdministrationService'];
