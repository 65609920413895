import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, List, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getGroups } from "../api/api";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
const RolesIndex = ({ $state }) => {
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const rolesData = await getGroups();
                setRoles(rolesData);
            }
            catch (error) {
                console.error("Erro ao carregar utilizadores:", error.message);
            }
        };
        if (roles.length === 0) {
            fetchUsers();
        }
    }, [roles]);
    const handleEditClick = (id) => {
        $state.go("app.administration.roles.editV2", { id: id });
    };
    return (<List>
      {roles.map((role) => (<ListItem key={role.id} button onClick={() => handleEditClick(role.id)}>
          <ListItemText primary={role.name} secondary={role.description}/>
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(role.id)}>
              <Icon path={mdiPencil} size={1.1}/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>))}
    </List>);
};
export default RolesIndex;
