import { IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, List, TextField, Stack, Tooltip, ThemeProvider, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiDelete, mdiPencil, mdiLockAlert, mdiLockOpen } from "@mdi/js";
import UserAvatar from "./UserAvatar";
import { theme } from "../wizard/utils";
import SimpleModal from "../hooks/utils/SimpleModal";
import SnackbarComponent from "../hooks/utils/Snackbar";
const UsersIndex = ({ $state, AdministrationService }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [inputText, setInputText] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [userToDelete, setUserToDelete] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackSeverity, setSnackSeverity] = useState("");
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const resource = await AdministrationService.getUsers();
                const usersData = resource.map((r) => ({
                    name: r.name,
                    email: r.email,
                    id: r.id,
                    createdAt: r.cratedAt,
                    lastLogin: r.lastLogin,
                    imap: r.imap,
                    blocked: r.blocked,
                    emailVerified: r.emailVerified,
                    entityIds: r.entityIds,
                }));
                setUsers(usersData);
                setFilteredUsers(usersData);
            }
            catch (error) {
                console.error("Erro ao carregar utilizadores:", error.message);
            }
        };
        if (users.length === 0) {
            fetchUsers();
        }
    }, [users]);
    const handleEditClick = (id) => {
        $state.go("app.administration.users.editV2", { id: id });
    };
    const handleLockClick = (id) => {
        users.map(async (user) => {
            if (user.id === id) {
                try {
                    user.blocked = !user.blocked;
                    await AdministrationService.saveUser(user);
                    setSnackMessage(user.blocked ? "Utilizador bloqueado" : "Utilizador desbloqueado");
                    setSnackSeverity("normal"); // normal Snackbar || success, info, warning, error -> alert Snackbar
                    setOpenSnackbar(true);
                }
                catch (error) {
                    console.error("Erro ao bloquear utilizador:", error.message);
                    setSnackMessage("Erro ao bloquear utilizador");
                    setSnackSeverity("error"); // normal Snackbar || success, info, warning, error -> alert Snackbar
                    setOpenSnackbar(true);
                }
            }
        });
    };
    const handleModal = (message) => {
        setModalOpen(true);
        setModalMessage(message);
    };
    const handleModalClose = (result) => {
        setModalOpen(false);
        if (result) {
            removeUser();
        }
    };
    const removeUser = async () => {
        if (userToDelete !== null) {
            try {
                const remove = await AdministrationService.removeUser(userToDelete);
                setSnackMessage("Utilizador removido com sucesso!");
                setSnackSeverity("success");
                setOpenSnackbar(true);
                setInputText("");
                setUsers([]);
                // normal Snackbar || success, info, warning, error -> alert Snackbar
            }
            catch (error) {
                console.error("Erro ao remover utilizador:", error);
                setSnackMessage("Erro ao remover utilizador!");
                setSnackSeverity("error");
                setOpenSnackbar(true);
            }
        }
    };
    const handleDeleteModal = (user) => {
        handleModal("Deseja remover utilizador " + user.name + " do sistema?");
        setUserToDelete(user);
    };
    const handleText = (e) => {
        // search by name or email
        const filtered = users.filter((user) => user.name.includes(e.target.value) ||
            user.email.includes(e.target.value));
        setFilteredUsers(filtered);
        setInputText(e.target.value);
    };
    return (<ThemeProvider theme={theme}>
      <List>
        <div style={{ width: "50%", margin: "auto" }}>
          <TextField id="outlined-basic" label="Pesquisa" variant="standard" fullWidth value={inputText} onChange={handleText}/>
        </div>
        {filteredUsers.map((user) => (<ListItem key={user.id} button onClick={() => handleEditClick(user.id)}>
            <ListItemAvatar>
              <UserAvatar name={user.name}/>
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={user.email}/>
            <ListItemSecondaryAction>
              <Stack direction="row" spacing={1}>
                <Tooltip title="Editar utilizador">
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(user.id)}>
                    <Icon path={mdiPencil} size={1.1}/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={user.blocked
                ? "Desbloquear utilizador"
                : "Bloquear utilizador"}>
                  <IconButton edge="end" aria-label="lock" onClick={() => handleLockClick(user.id)}>
                    {user.blocked ? (<Icon path={mdiLockOpen} size={1.1}/>) : (<Icon path={mdiLockAlert} size={1.1}/>)}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Apagar utilizador">
                  <IconButton edge="end" aria-label="delete" 
        //onClick={() => handleModal("Deseja remover utilizador " + user.name +" do sistema?")}
        onClick={() => handleDeleteModal(user)}>
                    <Icon path={mdiDelete} size={1.1}/>
                  </IconButton>
                </Tooltip>
              </Stack>
            </ListItemSecondaryAction>
          </ListItem>))}
      </List>

      <SimpleModal open={modalOpen} onClose={handleModalClose} message={modalMessage}/>
      <SnackbarComponent message={snackMessage} open={openSnackbar} severity={snackSeverity} onClose={() => setOpenSnackbar(false)}/>
    </ThemeProvider>);
};
export default UsersIndex;
