const moment = require("moment-timezone");
export default class DashboardCo2Controller {
  constructor(DashboardCo2, $interval, $scope, $state) {
    this.DashboardCo2 = DashboardCo2;
    this.counters = {};

    this.getData();

    if (!$scope.$$destroyed) {
      this.interval = $interval(() => {
        this.getData();
      }, 2 * 1000 * 60);
    }

    $scope.$on("$destroy", () => {
      if (this.interval) $interval.cancel(this.interval);
    });
  }

  getData = () => {
    this.environmentLoaded = false;
    this.DashboardCo2.dashboardCO2().then((r) => {
      this.createEnvironmentChart(r);
      this.environmentLoaded = true;
    });

    this.environmentMonthLoaded = false;
    this.DashboardCo2.dashboardCO2(true).then((r) => {
      this.createEnvironmentMonthChart(r);
      this.environmentMonthLoaded = true;
    });

    this.environmentYearLoaded = false;
    this.DashboardCo2.dashboardCO2Year().then((r) => {
      this.createConsumptionYearChart(r);
      this.environmentYearLoaded = true;
    });
  };

  createEnvironmentChart = (origin) => {
    let labels = [];
    let data = [[]];
    let datasets = [];
    let series = ["Consumo"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.value);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: "#4D5360",
        borderColor: "#4D5360"
      });
    });

    this.environment = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "kgCO₂/kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  createEnvironmentMonthChart = (origin) => {
    let labels = [];
    let data = [[]];
    let datasets = [];
    let series = ["Consumo"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.value);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: "#4D5360",
        borderColor: "#4D5360"
      });
    });

    this.environmentMonth = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("DD/MM");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "kgCO₂/kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };

  createConsumptionYearChart = (origin) => {
    let labels = [];
    let data = [[]];
    let datasets = [];
    let series = ["Consumo"];

    origin.forEach((row) => {
      labels.push(row.date);
      data[0].push(row.value);
    });

    data.forEach((r, i) => {
      datasets.push({
        label: series[i],
        data: r,
        backgroundColor: "#4D5360",
        borderColor: "#4D5360"
      });
    });

    this.environmentYear = {
      labels: labels,
      data: data,
      datasets: datasets,
      series: series,
      options: {
        tooltips: {
          enabled: true
        },
        elements: {
          line: {
            fill: false
          },
        },
        legend: {
          display: false
        },
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                callback: function (value, index, values) {
                  return moment.utc(value).format("MMM/YY");
                }
              }
            }
          ],

          yAxes: [
            {
              id: "kgCO₂/kWh",
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,

                callback: function (value, index, values) {
                  return (Math.round((value + Number.EPSILON) * 100) / 100);
                }
              }
            }
          ]
        }
      }
    };
  };
}

DashboardCo2Controller.$inject = ["DashboardService", "$interval", "$scope", "$state"];
