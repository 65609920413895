import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button, Checkbox, ListItemButton, ListItemIcon, ListItemText, List, Typography, ThemeProvider, } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../wizard/utils";
import SnackbarComponent from "../../hooks/utils/Snackbar";
const ModalTemplate = ({ user, setUser, modalType, open, onClose, entities, administrationService, }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [userEntities, setUserEntities] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [newName, setNewName] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const handleClose = (message, severity) => {
        // close modal
        if (modalType == 2) {
            setUserEntities([]);
        }
        setErrorMessage("");
        onClose(message, severity);
    };
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const changeNameEmail = async (newName, newEmail) => {
        try {
            if (newName.length !== 0 && newEmail.length !== 0) {
                await administrationService.changeNameEmail(user.id, newName, newEmail);
                user.name = newName;
                user.email = newEmail;
                setUser(user);
                handleClose("Nome e email alterados!", "success");
            }
        }
        catch (error) {
            console.error("Erro ao atualizar o nome e email:", error);
            handleClose("Erro ao atualizar o nome e email!", "error");
        }
    };
    const changePassword = async () => {
        if (newPassword.length === 0 || confirmPassword.length === 0) {
            setErrorMessage("Campo obrigatório");
            return; // Evita que a função continue se os campos estiverem vazios
        }
        if (newPassword === confirmPassword) {
            try {
                await administrationService.changePassword(user.id, newPassword);
                handleClose("Password alterada!", "success");
            }
            catch (error) {
                console.error("Erro ao atualizar password:", error);
            }
        }
        else {
            setOpenSnackbar(true);
        }
    };
    const updateEntities = async () => {
        const ent = [];
        if (userEntities.length !== 0) {
            try {
                userEntities.map((ents) => {
                    // verificar empresas com status = true
                    if (ents.status)
                        ent.push(ents.id);
                });
                user.entityIds = ent;
                await administrationService.saveUser(user);
                handleClose("Empresas associadas", "success");
            }
            catch (error) {
                console.error("Erro ao associar empresas:", error);
            }
        }
    };
    const handleToggle = (value) => {
        /// entities checkBox (handle click)
        setUserEntities((prevEntities) => prevEntities.map((entity) => {
            if (entity.id === value) {
                const stat = !entity.status;
                return { id: entity.id, name: entity.name, status: stat };
            }
            else {
                return entity;
            }
        }));
    };
    const entList = () => {
        // inserir todas as empresas no userEntities
        entities.map((entity) => {
            if (user.entityIds.includes(entity.id)) {
                // se estiver associada ao user -> status: true
                setUserEntities((userEntities) => [
                    ...userEntities,
                    { id: entity.id, name: entity.name, status: true },
                ]);
            }
            else {
                // se não estiver associada ao user -> status: false
                setUserEntities((userEntities) => [
                    ...userEntities,
                    { id: entity.id, name: entity.name, status: false },
                ]);
            }
        });
    };
    const setNameEmail = () => {
        if (newName.length === 0) {
            setNewName(user.name);
        }
        if (newEmail.length === 0) {
            setNewEmail(user.email);
        }
    };
    useEffect(() => {
        setOpenModal(open);
        if (open && modalType === 2) {
            entList();
        }
        else if (open && modalType === 0) {
            setNameEmail();
        }
    }, [open, newName, newEmail]);
    switch (modalType) {
        case 0:
            return (<ThemeProvider theme={theme}>
          <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="modalStyle">
              <Stack direction="column" spacing={2}>
                <div className="col-md-15">
                  <TextField id="name" label="Novo nome" variant="standard" fullWidth defaultValue={user.name} onChange={(e) => setNewName(e.target.value)}/>
                </div>
                <div className="col-md-15">
                  <TextField id="email" label="Email" variant="standard" fullWidth defaultValue={user.email} onChange={(e) => setNewEmail(e.target.value)}/>
                </div>
                <div className="col-md-15">
                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Button sx={{ color: "black" }} onClick={() => handleClose}>
                      Cancelar
                    </Button>
                    <Button onClick={() => changeNameEmail(newName, newEmail)}>
                      Ok
                    </Button>
                  </Stack>
                </div>
              </Stack>
            </Box>
          </Modal>
        </ThemeProvider>);
        case 1:
            return (<ThemeProvider theme={theme}>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="modalStyle">
              <Stack direction="column" spacing={2}>
                <div className="col-md-15">
                  <TextField id="password" label="Nova Password" type="password" variant="standard" fullWidth onChange={(e) => setNewPassword(e.target.value)} required error={errorMessage !== ""} helperText={errorMessage}/>
                </div>
                <div className="col-md-15">
                  <TextField id="passwordCheck" label="Confirmar Password" type="password" variant="standard" fullWidth onChange={(e) => setConfirmPassword(e.target.value)} required error={errorMessage !== ""} helperText={errorMessage}/>
                </div>
                <div className="col-md-15">
                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Button sx={{ color: "black" }} onClick={() => handleClose}>
                      Cancelar
                    </Button>
                    <Button onClick={changePassword} type="submit">
                      Ok
                    </Button>
                  </Stack>
                </div>
              </Stack>
            </Box>
          </Modal>

          <SnackbarComponent message={"Passwords não coincidem!"} open={openSnackbar} severity={"warning"} onClose={closeSnackbar}/>
        </ThemeProvider>);
        case 2:
            return (<ThemeProvider theme={theme}>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className="modalEntitiesStyle">
              <Typography align="center" variant="h6">
                <br />
                Empresas associadas
              </Typography>

              <Stack direction="column">
                <List disablePadding>
                  {userEntities.map((entity, index) => (<ListItemButton role={undefined} key={index} onClick={() => handleToggle(entity.id)} dense>
                      <ListItemIcon>
                        <Checkbox edge="start" tabIndex={-1} disableRipple checked={entity.status}/>
                      </ListItemIcon>
                      <ListItemText primary={entity.name}/>
                    </ListItemButton>))}
                </List>
                <div className="col-md-15">
                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Button sx={{ color: "black" }} onClick={() => handleClose}>
                      Cancelar
                    </Button>
                    <Button onClick={updateEntities}>Ok</Button>
                  </Stack>
                </div>
              </Stack>
            </div>
          </Modal>
        </ThemeProvider>);
        default:
            return null;
    }
};
export default ModalTemplate;
